import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import b1 from "../../../../assets/blogs/b1.png";
import b2 from "../../../../assets/blogs/b2.png";
import b3 from "../../../../assets/blogs/b3.png";

import "./styles/Blog.scss";
import {
  deleteEvent,
  getAllEvent,
} from "../../../../services/eventsservices/events_services";

import Pagination from "rc-pagination";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";

function Events() {
  const [show, setShow] = useState(false);
  const [blogid, setBlogId] = useState("");
  const handleClose = () => {
    setShow(false);
  };
  const [allblog, setAllBlog] = useState([]);
  const [sellerLimit, setSellerLimit] = useState([]);
  const [sellerCurrent, setSellerCurrent] = useState(1);
  const router = useNavigate();
  const NavigateCreate = () => {
    router("/womeyn/events/create");
  };
  const datas = [1, 2, 3, 4, 5, 6, 7];
  const Navigatepath = (id) => {
    router(`/womeyn/events/create/${id}`);
  };

  useEffect(() => {
    getAllEvent()
      .then((res) => {
        setAllBlog(res?.results);
        setSellerLimit(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [blogid]);

  const DeleteBlog = (id) => {
    setBlogId(id);
    setShow(true);
  };

  const ConfirmDelete = () => {
    const formsDats = new FormData();
    formsDats.append("eventId", blogid);
    formsDats.append("stateId", 5);
    const overall = allblog.filter((item) => {
      return item?.id !== blogid;
    });
    deleteEvent(formsDats)
      .then((res) => {
        toast.success("Event Deleted");
        setAllBlog(overall);
        setTimeout(() => {
          handleClose();
        }, 500);
      })
      .catch((err) => {});
  };

  const BlogView = (id) => {
    router(`/womeyn/events/${id}`);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  const fetchSellerCurrentData = async (sellerCurrent) => {
    const resSellerData = await getAllEvent(sellerCurrent);
    setAllBlog(resSellerData?.results);
    setSellerLimit(resSellerData);
  };
  const handleSellerChangePagecount = async (e) => {
    setSellerCurrent(e);
    const sellerCurrent = e;
    await fetchSellerCurrentData(sellerCurrent);
  };

  return (
    <>
      <Card>
        <div>
          <div className="d-flex justify-content-between p-2 blogbox">
            <div>
              <h4>Events</h4>
            </div>
            <div>
              <button className="AddBlog" onClick={NavigateCreate}>
                + Add a New Event
              </button>
            </div>
          </div>
        </div>

        <div className="cards row ">
          {allblog?.map((item, index) => {
            return (
              <div className="card col-lg-3" key={index}>
                <div className="inside-cards">
                  <div>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${item?.eventImageName}`}
                      alt="no image"
                      className="imageblogs"
                    />
                  </div>
                  <div>
                    <div>
                      {item?.title?.length > 20 ? (
                        <>{item?.title?.slice(0, 20)}...</>
                      ) : (
                        <>{item?.title}</>
                      )}
                    </div>
                    <div>
                      {item?.description?.length > 50 ? (
                        <>{item?.description.slice(0, 60)}...</>
                      ) : (
                        <>{item?.description}</>
                      )}
                    </div>
                    <div>startDate : {item?.startDate}</div>
                    <div>endDate : {item?.endDate}</div>
                  </div>
                </div>
                <div className="icons">
                  {/* <div>
                                        <img src={b3} alt="no image" className="blogsicon" onClick={() => BlogView(item?.id)} />
                                    </div> */}
                  <div onClick={() => Navigatepath(item?.id)}>
                    <img src={b1} alt="no image" className="blogsicon" />
                  </div>{" "}
                  <div>
                    <img
                      src={b2}
                      alt="no image"
                      className="blogsicon"
                      onClick={() => DeleteBlog(item?.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div>
            <Pagination
              className="pagination-data mt-3"
              total={sellerLimit.totalPages * 10}
              onChange={handleSellerChangePagecount}
              current={sellerCurrent}
              itemRender={PrevNextArrow}
              breakLabel="..."
            />
          </div>
        </div>
      </Card>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this post?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={ConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Events;
