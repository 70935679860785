import { HttpInterceptor } from "../auth/http-interceptors";

export function createBlog(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post("/admin/blog-post", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllBlog(page) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(
      `/admin/blog-post?sortBy=updatedAt:desc&&limit=12&&page=${page}`
    )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleBlog(id) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(`/admin/blog-post/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateBlog(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/blog-post`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteBlog(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/blog-post`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function singleBlog(id) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(`/admin/blog-post/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
