import { HttpInterceptor } from "../auth/http-interceptors";

export function createEvent(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post("/admin/events", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllEvent(page) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(
      `/admin/events?sortBy=updatedAt:desc&&limit=12&&page=${page}`
    )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleEvent(id) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(`/admin/events/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateEvent(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/events`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteEvent(data) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/admin/events`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function singleEvent(id) {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(`/admin/events/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
